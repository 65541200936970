import { default as _91slug_932B6qg6wX0jMeta } from "/app/pages/event-2024/[slug].vue?macro=true";
import { default as _91slug_93sUbkJDVPd1Meta } from "/app/pages/event/[slug].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: "event-2024-slug",
    path: "/event-2024/:slug()",
    component: () => import("/app/pages/event-2024/[slug].vue").then(m => m.default || m)
  },
  {
    name: "event-slug",
    path: "/event/:slug()",
    component: () => import("/app/pages/event/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  }
]