<script setup>
onMounted(() => {
  // This will load the CookieYes script when the component is mounted
  const script = document.createElement("script");
  script.id = "cookieyes";
  script.src =
    "https://cdn-cookieyes.com/client_data/9c10509e287a1710f245c5bd/script.js";
  script.async = true;
  document.head.appendChild(script);
});
</script>

<template>
  <div>
    <NuxtPage />
  </div>
</template>

<style>
@import url(/assets/styles/typography.scss);
@import url(/assets/styles/variables.scss);

.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>